import React, { useEffect, useRef, useState } from 'react';
import './main.css'
import { useParams } from 'react-router-dom';
import * as echarts from 'echarts';
import { SVGRenderer } from 'echarts/renderers';

export default function Analytics({ sessions, groups }) {
    
    const { pid  } = useParams();
    const [s, sets] = useState()
    const [g, setg] = useState()
    const [gname, setgname] = useState()

    const [delpopup, setdelpopup] = useState(false)
    //const delpopup = useRef(false)
    
    //const [averagerr, setaveragerr] = useState()
    //const [winrate, setwinrate] = useState()
    const [balancedata, setbalancedata] = useState([])
    const [datedata, setdatedata] = useState([])
    const [analytics, setanalytics] = useState({})
    const balancechartref = useRef()
    const calendarchartref = useRef()

    
  useEffect(() => {
    if (balancechartref.current && document.getElementById("analytics-balancechart") !== null) {
        
        echarts.use([SVGRenderer]);
        var myChart = echarts.init(document.getElementById("analytics-balancechart"), null, { renderer: 'svg' }); 
        var option;
        
        window.addEventListener('resize', function() {
          myChart.resize();
        });
        
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              type: 'cross',
              lineStyle: {
                color: '#E9F2FF',
                width: 1,
                opacity: 1
              }
            }
          },
          xAxis: {
            type: 'category',
            show: false,
            data: datedata,
            boundaryGap: false,
            axisLine: { lineStyle: { color: '#8392A5' } }
          },
          yAxis: {
            scale: true,
            axisLine: { lineStyle: { color: '#8392A5' } },
            splitLine: { show: false }
          },
          grid: {
            show: false,
            left: '0px',
            right: '0px',
            top: '0px',
            bottom: '0px',
            tooltip: {
              show: true,
              axisPointer: {
                type: "none",
              },
              trigger: 'axis',
            }
          },
          series: [
            {
                name: 'Balance',
                type: 'line',
                smooth: true,
                lineStyle: {
                  width: 1
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#1D7AFC'
                    },
                    {
                      offset: 1,
                      color: '#1D7AFC00'
                    }
                  ])
                },
                emphasis: {
                  focus: 'series'
                },
                data: balancedata
              }
          ]
        };
        
        option && myChart.setOption(option);
        balancechartref.current = myChart
    }

    function getVirtualData(dstart, dend, ops) {
        const date = +echarts.time.parse(dstart.getFullYear() + '-' + dstart.getMonth() + 1 + '-' + dstart.getDate());
        const end = +echarts.time.parse(dend.getFullYear() + '-' + dend.getMonth() + 1 + '-' + dend.getDate());
        //const date = +echarts.time.parse(year + '-01-01');
        //const end = +echarts.time.parse(year + '-12-31');
        const dayTime = 3600 * 24 * 1000;
        const data = [];
        for (let time = date; time <= end; time += dayTime) {
            let sumforday = 0
            ops.forEach(op => {
                let checkdate = new Date(op.closedate)
                let checktime = new Date(time)
                if (String((checkdate.getMonth() + 1))+String(checkdate.getDate()) === String((checktime.getMonth() + 1))+String(checktime.getDate())) {
                    sumforday += op.finalmoney
                }
            });
            
            data.push([ echarts.time.format(time, '{yyyy}-{MM}-{dd}', false), sumforday ]);
        }
        return data;
      }

    //calendar chart
    setTimeout(() => {
        if (calendarchartref && document.getElementById('calendarchart') !== null && s !== undefined && s.operations.length > 0) {
            echarts.use([SVGRenderer]);

            var calendarChart = echarts.init(document.getElementById('calendarchart'), null, { renderer: 'svg' });
            var calendaroption;
            var startdate = new Date(s?.sdatestart)
            var enddate = new Date(s?.sdateend)
        
            window.addEventListener('resize', function() {
                calendarChart.resize();
              });

              calendaroption = {
                backgroundColor: 'transparent',
                tooltip: {
                  formatter: function (params) {
                    return 'Return: ' + params.value[1].toFixed(2);
                  }
                },
                visualMap: {
                  show: false,
                  min: -100,
                  max: 100,
                  inRange: {
                    color: ['#B7072C', '#252B2D', '#228B22'],
                    opacity: 1
                  },
                },
                calendar: {
                    left: 50,
                    top: 20,
                    right: 0,
                    bottom: 0,
                    dayLabel: {
                        color: '#E9F2FF'
                    },
                    monthLabel: {
                        color: '#E9F2FF'
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#E9F2FF80"
                        }
                    },
                    range: [String(startdate.getFullYear() + '-' + startdate.getMonth() + 1), String(enddate.getFullYear() + '-' + enddate.getMonth() + 2)],
                },
                series: {
                  type: 'heatmap',
                  coordinateSystem: 'calendar',
                  data: getVirtualData(startdate, enddate, s?.operations)
                }
              };
              
              calendaroption && calendarChart.setOption(calendaroption);
              calendarchartref.current = calendarChart
        } if (calendarchartref && document.getElementById('calendarchart') !== null && g !== undefined) {
            echarts.use([SVGRenderer]);
            
            calendarChart = echarts.init(document.getElementById('calendarchart'), null, { renderer: 'svg' });
            startdate = new Date(g[0]?.sdatestart)
            enddate = new Date(g[0]?.sdatestart)
        
            window.addEventListener('resize', function() {
                calendarChart.resize();
            });

            calendaroption = {
            backgroundColor: 'transparent',
            tooltip: {
                formatter: function (params) {
                return 'Return: ' + params.value[1].toFixed(2);
                }
            },
            visualMap: {
                show: false,
                min: -100,
                max: 100,
                inRange: {
                color: ['#B7072C', '#252B2D', '#228B22'],
                opacity: 1
                },
            },
            calendar: {
                left: 50,
                top: 20,
                right: 0,
                bottom: 0,
                range: '2024',
                dayLabel: {
                    color: '#E9F2FF'
                },
                monthLabel: {
                    color: '#E9F2FF'
                },
                splitLine: {
                    lineStyle: {
                        color: "#E9F2FF80"
                    }
                },
                //range: [String(startdate.getFullYear() + '-' + startdate.getMonth() + 1), String(enddate.getFullYear() + '-' + enddate.getMonth() + 2)],
            },
            series: {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: getVirtualData(startdate, enddate, analytics.ops)
            }
            };
              
              calendaroption && calendarChart.setOption(calendaroption);
              calendarchartref.current = calendarChart
        }
    }, 500);
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datedata]);

    useEffect(() => {
        var querydata = []

        sessions.forEach(ses => {
            if (parseFloat(pid) === ses.sid) {
                sets(ses)
                //average rr, winrate
                setbalancedata((currentItems) => [...currentItems, ses.sbalancestart])
                let startlabeldate = new Date(ses.operations[0]?.filldate)
                setdatedata((currentItems) => [...currentItems, `${startlabeldate.getHours()}:${startlabeldate.getMinutes()} ${startlabeldate.getDate()} ${startlabeldate.toLocaleString('default', { month: 'short' })} ${startlabeldate.getFullYear()}`])
                let balancetrack = ses.sbalancestart
                let rrs = 0
                let winnedtrades = 0
                let balanceupdate = ses.sbalancestart
                let grossprofit = 0
                let grossloss = 0
                let peak = 0;
                let maxdd = 0;
                let winscount = 0
                let losscount = 0
                let balancehistory = []
                let dateshistory = []
                ses.operations.forEach(op => {
                    balancehistory.push((balanceupdate + op.finalmoney).toFixed(2))
                    balanceupdate = balanceupdate + op.finalmoney
                    let labeldate = new Date(op.filldate)
                    dateshistory.push(`${labeldate.getHours()}:${labeldate.getMinutes()} ${labeldate.getDate()} ${labeldate.toLocaleString('default', { month: 'short' })} ${labeldate.getFullYear()}`)
                    //console.log(datedata)
                    //labeldate.toTimeString().split(' ')[0]
                    if (op.finalmoney > 0) {
                        grossprofit += op.finalmoney
                        winnedtrades += 1
                        winscount += 1
                    }
                    if (op.finalmoney < 0) {
                        grossloss += op.finalmoney
                        losscount += 1
                    }
                    if (balancetrack > peak) {
                        peak = balancetrack;
                    } else {
                        const drawdown = peak - balancetrack;
                        maxdd = Math.max(maxdd, drawdown);
                    }
                    balancetrack += op.finalmoney
                    rrs += parseFloat((op.tpvalue/op.slvalue).toFixed(2))
                });
                setbalancedata((currentItems) => [...currentItems, ...balancehistory])
                setdatedata((currentItems) => [...currentItems, ...dateshistory])
                setanalytics(prevState => ({...prevState, 
                    grossprofit: grossprofit, 
                    grossloss: grossloss, 
                    maxdd: maxdd, 
                    averagerr: Math.abs(rrs / ses.operations.length),
                    winrate: winnedtrades / ses.operations.length * 100, 
                    averagewin: grossprofit / winscount, 
                    averageloss: grossloss / losscount, 
                    winscount: winscount,
                    losscount: losscount,
                }));
            }
            
            if (parseFloat(pid) === ses.sstrategy) {
                querydata.push(ses)
            }
        });

        if (querydata.length > 0) {
            setg(querydata)
            
            let ops = []
            let startbalance = 0
            let currentbalance = 0
            let operationsnumber = 0
            let balanceupdate = 0
            let balancetrack = 0
            let rrs = 0
            let winnedtrades = 0
            let grossprofit = 0
            let grossloss = 0
            let peak = 0;
            let maxdd = 0;
            let winscount = 0
            let losscount = 0
            let startdate = 0
            let enddate = 0
            let balancehistory = []
            let dateshistory = []

            querydata.forEach(s => {
                ops = ops.concat(s.operations)
                startbalance += s.sbalancestart
                currentbalance += s.sbalancecurrent
                operationsnumber += s.operations.length
                balanceupdate += s.sbalancestart
                balancetrack += s.sbalancestart
            });
            
            
            ops.forEach(op => {
                balancehistory.push((balanceupdate + op.finalmoney).toFixed(2))
                balanceupdate = balanceupdate + op.finalmoney
                let labeldate = new Date(op.closedate)
                dateshistory.push(`${labeldate.getHours()}:${labeldate.getMinutes()} ${labeldate.getDate()} ${labeldate.toLocaleString('default', { month: 'short' })} ${labeldate.getFullYear()}`)
                if (op.finalmoney > 0) {
                    grossprofit += op.finalmoney
                    winnedtrades += 1
                    winscount += 1
                }
                if (op.finalmoney < 0) {
                    grossloss += op.finalmoney
                    losscount += 1
                }
                if (balancetrack > peak) {
                    peak = balancetrack;
                } else {
                    const drawdown = peak - balancetrack;
                    maxdd = Math.max(maxdd, drawdown);
                }
                balancetrack += op.finalmoney
                rrs += parseFloat((op.tpvalue/op.slvalue).toFixed(2))
            });

            let startlabeldate = new Date(ops[0]?.closedate)
            balancehistory.unshift(startbalance)
            dateshistory.unshift(`${startlabeldate.getHours()}:${startlabeldate.getMinutes()} ${startlabeldate.getDate()} ${startlabeldate.toLocaleString('default', { month: 'short' })} ${startlabeldate.getFullYear()}`)
            setbalancedata((currentItems) => [...currentItems, ...balancehistory])
            setdatedata((currentItems) => [...currentItems, ...dateshistory])

            setanalytics(prevState => ({...prevState, 
                sbalancestart: startbalance,
                sbalancecurrent: currentbalance,
                grossprofit: grossprofit, 
                grossloss: grossloss, 
                maxdd: maxdd, 
                operations: operationsnumber,
                ops: ops,
                averagerr: Math.abs(rrs / operationsnumber),
                winrate: winnedtrades / operationsnumber * 100, 
                averagewin: grossprofit / winscount, 
                averageloss: grossloss / losscount, 
                startdate: startdate,
                enddate: enddate,
                winscount: winscount,
                losscount: losscount,
            }));


            groups.forEach(g => {
                if (g.gid === Number(pid)) {
                    setgname(g.gname)
                }
            });
        }

        /*
        querydata.forEach(d => {
            console.log(d)
            finaldata.operations = finaldata.operations.concat(d.operations)
        });
        */
    }, [sessions])

    const deletethisg = () => {
        fetch(process.env.REACT_APP_BACKEND+"/api/delthisg",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({gid: pid})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setdelpopup(false)
            }
            //console.log(data)
        })
        .catch(err=>{})
    }

    //strategy analytics
    if (s === undefined && g?.length !== 0) {
      return (
        <div className="statsdisplay">
            <div className="analytics-navbar">
                <h1 className="analytics-navbar-text">{gname} - Analytics</h1>
                <div className="analytics-navbar-inputs">
                    <p style={{color: "#B7072C", cursor: "pointer"}} onClick={() => {setdelpopup(true)}} >Delete</p>
                    <select className='analytics-inputs'>
                        <option value="EUR/USD">All Days</option>
                        <option value="GBP/USD">Monday</option>
                        <option value="USD/JPY">Tuesday</option>
                        <option value="USD/JPY">Wednesday</option>
                        <option value="USD/JPY">Thursday</option>
                        <option value="USD/JPY">Friday</option>
                    </select>
                    <select className='analytics-inputs'>
                        {
                            g?.map((s,k) => {
                                return (<option key={k} value={s.sasset}>{s.sasset}</option>)
                            })
                        }
                        
                    </select>
                </div>
            </div>
            <div className="analytics-cont">
                <div className="analytics-balancechart-cont">
                    <div className="analytics-main-metrics">
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Account Balance:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">${analytics.sbalancestart ? (analytics.sbalancecurrent).toFixed(2) : ''}</p>
                                <p className={analytics ? ((analytics.sbalancecurrent - analytics.sbalancestart) * 100 / analytics.sbalancestart).toFixed(2) > 0 ? "a-m-percentage resgreen" : "a-m-percentage resred" : ''}>{analytics ? ((analytics.sbalancecurrent - analytics.sbalancestart) * 100 / analytics.sbalancestart).toFixed(2) : ''}%</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Total PnL:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">${analytics.sbalancestart ? (analytics.sbalancecurrent - analytics.sbalancestart).toFixed(2) : ''}</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Average RR:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{analytics.sbalancestart ? (analytics.averagerr).toFixed(2) : ''}</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Win Rate:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{analytics.sbalancestart ? (analytics.winrate).toFixed(2) : ''}%</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Trade Count:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{analytics.sbalancestart ? analytics.operations : ''}</p>
                                <p className={analytics.winscount > analytics.losscount ? "a-m-percentage resgreen" : "a-m-percentage resred"} >{analytics.winscount+"/"+analytics.losscount}</p>
                            </div>
                        </div>
                    </div>
                    <div className="analytics-balancechart" id='analytics-balancechart' ref={balancechartref}></div>
                </div>
                <div className="act-cont">
                    <div className="a-t-c">
                        <table className="a-t-table">
                            <tr>
                                <td>Gross Profit</td>
                                <td>{analytics.sbalancestart ? (analytics.grossprofit).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Gross Loss</td>
                                <td>{analytics.sbalancestart ? (analytics.grossloss).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Average Win</td>
                                <td>{analytics.sbalancestart ? (analytics.averagewin).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Average Loss</td>
                                <td>{analytics.sbalancestart ? (analytics.averageloss).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Maximum drawdown</td>
                                <td>{analytics.sbalancestart ? (analytics.maxdd*100/analytics.sbalancestart).toFixed(2) : ''}%</td>
                            </tr>
                        </table>
                    </div>
                    <div className="a-t-c2">
                        <table className="a-t-table2">
                            {/* <tr>
                                <td>Sharpe ratio</td>
                                <td>answer</td>
                            </tr> */}
                            <tr>
                                <td>Profit factor</td>
                                <td>{analytics.sbalancestart ? (analytics.grossprofit/analytics.grossloss).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Recovery factor</td>
                                <td>{analytics.sbalancestart ? ((analytics.sbalancecurrent - analytics.sbalancestart) / (analytics.maxdd*100/analytics.sbalancestart)).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Expectancy</td>
                                <td>{analytics.sbalancestart ? ((analytics.winrate / 100  * analytics.averagewin) - ((100 - analytics.winrate) / 100 * analytics.averageloss)).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Annualized return</td>
                                <td>{analytics.sbalancestart ? (((analytics.sbalancestart / analytics.sbalancecurrent) ** (1 / 1)) - 1).toFixed(2) : ''}</td>
                            </tr>
                            <tr>
                                <td>Average risk reward</td>
                                <td>{analytics.sbalancestart ? (analytics.averagerr).toFixed(2) : ''}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="a-p-t" id='calendarchart' ref={calendarchartref}></div>
                <div className="more-coming-soon">
                    More coming soon!
                </div>
            </div>
            {delpopup ?
                <div className="strategy-create-popup-bg" onClick={(e) => {if (e.target === e.currentTarget) {setdelpopup(false)}}}>
                    <div>
                        <div className="session-create-popup-topbar">
                            <h1 className="popup-title">Are you sure you want to delete this group?</h1>
                            <img className='popup-exit' src="/" alt="" />
                        </div>
                        <div className="popup-actions">
                            <button type='button' className="popup-cancel" onClick={(e) => {setdelpopup(false)}}>No!</button>
                            <button type='submit' className="popup-Accept" style={{color: "#B7072C"}} onClick={deletethisg}>Yes!</button>
                        </div>
                    </div>
                </div>
            : ""}
        </div>
      )
    } if (s === undefined) {
        return (<h1>UPS... Something went wrong. Try again.</h1>)
    }

    //session analytics
    return (
        <div className="statsdisplay">
            <div className="analytics-navbar">
                <h1 className="analytics-navbar-text">{s.sname} - Analytics</h1>
                <div className="analytics-navbar-inputs">
                    <select className='analytics-inputs'>
                        <option value="EUR/USD">All Days</option>
                        <option value="GBP/USD">Monday</option>
                        <option value="USD/JPY">Tuesday</option>
                        <option value="USD/JPY">Wednesday</option>
                        <option value="USD/JPY">Thursday</option>
                        <option value="USD/JPY">Friday</option>
                    </select>
                    <select className='analytics-inputs'>
                        <option value={s.sasset}>{s.sasset}</option>
                    </select>
                </div>
            </div>
            <div className="analytics-cont">
                <div className="analytics-balancechart-cont">
                    <div className="analytics-main-metrics">
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Account Balance:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">${(s.sbalancecurrent).toFixed(2)}</p>
                                <p className={((s.sbalancecurrent - s.sbalancestart) * 100 / s.sbalancestart).toFixed(2) > 0 ? "a-m-percentage resgreen" : "a-m-percentage resred"}>{((s.sbalancecurrent - s.sbalancestart) * 100 / s.sbalancestart).toFixed(2)}%</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Total PnL:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">${(s.sbalancecurrent - s.sbalancestart).toFixed(2)}</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Average RR:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{(analytics.averagerr).toFixed(2)}</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Win Rate:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{(analytics.winrate).toFixed(2)}%</p>
                            </div>
                        </div>
                        <div className="vl"></div>
                        <div className="analytics-main-segment">
                            <div className="a-m-s-top">
                                <img src="/" alt="" />
                                <p className="a-m-title">Trade Count:</p>
                            </div>
                            <div className="a-m-s-bot">
                                <p className="a-m-value">{s.operations.length}</p>
                                <p className={analytics.winscount > analytics.losscount ? "a-m-percentage resgreen" : "a-m-percentage resred"} >{analytics.winscount+"/"+analytics.losscount}</p>
                            </div>
                        </div>
                    </div>
                    <div className="analytics-balancechart" id='analytics-balancechart' ref={balancechartref}></div>
                </div>
                <div className="act-cont">
                    <div className="a-t-c">
                        <table className="a-t-table">
                            <tr>
                                <td>Gross Profit</td>
                                <td>{(analytics.grossprofit).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Gross Loss</td>
                                <td>{(analytics.grossloss).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Average Win</td>
                                <td>{(analytics.averagewin).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Average Loss</td>
                                <td>{(analytics.averageloss).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Maximum drawdown</td>
                                <td>{(analytics.maxdd*100/s.sbalancestart).toFixed(2)}%</td>
                            </tr>
                        </table>
                    </div>
                    <div className="a-t-c2">
                        <table className="a-t-table2">
                            {/* <tr>
                                <td>Sharpe ratio</td>
                                <td>answer</td>
                            </tr> */}
                            <tr>
                                <td>Profit factor</td>
                                <td>{(analytics.grossprofit/analytics.grossloss).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Recovery factor</td>
                                <td>{((s.sbalancecurrent - s.sbalancestart) / (analytics.maxdd*100/s.sbalancestart)).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Expectancy</td>
                                <td>{((analytics.winrate / 100  * analytics.averagewin) - ((100 - analytics.winrate) / 100 * analytics.averageloss)).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Annualized return</td>
                                <td>{(((s.sbalancestart / s.sbalancecurrent) ** (1 / 1)) - 1).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Average risk reward</td>
                                <td>{(analytics.averagerr).toFixed(2)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="a-p-t" id='calendarchart' ref={calendarchartref}>
                    {/* <table className="a-p-table">
                        <tr>
                            <th></th>
                            <th>January</th>
                            <th>February</th>
                            <th>March</th>
                            <th>April</th>
                            <th>May</th>
                            <th>June</th>
                            <th>July</th>
                            <th>August</th>
                            <th>September</th>
                            <th>October</th>
                            <th>November</th>
                            <th>December</th>
                            <th>Sum</th>
                        </tr>
                        <tr>
                            <td>2023</td>
                            <td style={{color: "#228B22"}}>+12%</td>
                            <td style={{color: "#B7072C"}}>-1%</td>
                            <td style={{color: "#228B22"}}>+12%</td>
                            <td style={{color: "#B7072C"}}>-1%</td>
                            <td style={{color: "#228B22"}}>+12%</td>
                            <td style={{color: "#B7072C"}}>-1%</td>
                            <td style={{color: "#228B22"}}>+12%</td>
                            <td style={{color: "#B7072C"}}>-1%</td>
                            <td style={{color: "#228B22"}}>+12%</td>
                            <td style={{color: "#B7072C"}}>-1%</td>
                            <td style={{color: "#228B22"}}></td>
                            <td style={{color: "#B7072C"}}></td>
                            <td style={{color: "#228B22"}}>+110%</td>
                        </tr>
                    </table> */}
                </div>
                <div className="more-coming-soon">
                    More coming soon!
                </div>
            </div>
        </div>
    )
}