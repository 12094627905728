import historyProvider from "./historyProvider";
import stream from "./stream";

const configurationData = {
  //supported_resolutions: ["1", "5", "10", "15", "30", "60", "120", "240", "D", "1W"],
};

const Datafeed = {
  onReady: (callback) => {
    setTimeout(() => callback({ configurationData }), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {},
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    const symbol_stub = {
      name: symbolName,
      description: "",
      type: "crypto",
      session: "24x7",
      timezone: "Asia/Singapore",
      ticker: symbolName,
      minmov: 1,
      pricescale: 100000,
      has_intraday: true,
      intraday_multipliers: ["1"],
      supported_resolution: ["1", "5", "10", "15", "30", "60", "120", "240", "D", "1W"],
      volume_precision: 0,
      data_status: "endofday",
    };

    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    /*
    const bars = []
    Object.entries(Object.entries(jsonData)).forEach(([key, value]) => {
      const date = new Date(value[0]);
      const timestampInMilliseconds = date.getTime();
      var tempbar = {
        time: timestampInMilliseconds,
        low: value[1].Low,
        high: value[1].High,
        open: value[1].Open,
        close: value[1].Close,
        volume: 1,
      }
      bars.push(tempbar)
    })
    console.log(bars)
    
    if (bars && bars.length > 0) {
      onHistoryCallback(bars, { noData: false });
    } else if (bars && bars.length < 1) {
      onHistoryCallback([], { noData: true });
    }
    */
    const bars = await historyProvider.getBars(
      symbolInfo,
      resolution,
      periodParams,
      periodParams.firstDataRequest
    );

    if (periodParams.firstDataRequest) {
      onHistoryCallback(bars, { noData: false });
    } else {
      onHistoryCallback([], { noData: true });
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    stream.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    );
  },
  unsubscribeBars: (subscriberUID) => {
    stream.unsubscribeBars(subscriberUID);
  },
};

export default Datafeed;
