import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';

const root = ReactDOM.createRoot(document.getElementById('root'));

// competitors
//https://traderbuddy.net/home


root.render(
    <App/>
)