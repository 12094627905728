import React, { useState } from 'react';
import './main.css'
import logo from "./public/rapidlogonobg.png"
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Login() {
    const { email } = useParams();
    const [emailinput, setemailinput] = useState(email);
    const [passinput, setpassinput] = useState();
    
    const sendlogin = () => {
        fetch(process.env.REACT_APP_BACKEND+"/api/login",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true,'Access-Control-Allow-Origin': "https://rapid-app-three.vercel.app"},
            body: JSON.stringify({email: emailinput, pass: passinput}),
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                ReactGA.event({
                  category: "Account",
                  action: "Signup/Login",
                });
                window.location = "/dashboard"
            }
        })
        .catch(err=>{console.log(err)})
    }


    return (
        <>
        <div className="login-cont">
            <div className="login-card">
                <img src={logo} alt="" className="card-logo" onClick={() => {window.location = "/"}} />
                <label className='login-label' htmlFor="">Email</label>
                <input type="email" className="login-input" defaultValue={email === "undefined" ? "" : email} onChange={(e) => {setemailinput(e.target.value)}}/>
                <label className='login-label' htmlFor="">Password</label>
                <input type="password" className="login-input" onChange={(e) => {setpassinput(e.target.value)}}/>
                <p style={{fontSize: '0.7rem', textAlign: 'center', width: '80%'}}>By continuing, you acknowledge that RapidFx is not associated with real-money trading.</p>
                <div className="card-actions">
                    <button type='submit' className="login-submit" onClick={sendlogin}>Log In</button>
                    {/* <button type='submit' className="login-submit">Sign Up</button> */}
                </div>
                {/* <a href="/" className='login-reset'>Forgot your password?</a> */}
                <div className="login-other">
                    <div className="card-actions">
                        <a href="/" className='login-reset'>Forgot your password?</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="mobile-ban">
            <img className='mobile-ban-logo' src={logo} alt="" />
            <h1>Wrong Device</h1>
            <p>RapidFx is only accessible from computers or wide screens.</p>
        </div>
        </>
    )
}