import { pair, year, month } from '../session.jsx'
import { progrescounter } from '../seschart.jsx'

/*
import { S3Client } from "@aws-sdk/client-s3";

const client = new S3Client({
    region: process.env.REACT_APP_BACKEND_SWA_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_BACKEND_SWA_KEY,
        secretAccessKey: process.env.REACT_APP_BACKEND_SWA_SECRET
    }
});
*/


export let next_feed;
export let c_name = null;

const history = {};
/*
async function getaws() {
    const input = {
        "Bucket": process.env.REACT_APP_BACKEND_SWA_BUCKET,
        "Key": "EURUSD/2015.json"
    };
    const command = new GetObjectCommand(input);
    //const response = await client.send(command).then(data => {console.log(data)})
    const response = await client.send(command).then(
        (data) => {
            console.log(data.Body.getReader().read().toString())
        },
        (error) => {
          console.log(error)
        }
    );
}
*/
const historyProvider = {
  history: history,
  getBars: async function (symbolInfo, resolution, periodParams, first) {

    //const chart_feed = require("../services/EURUSD_01.01.2023-31.12.2023.json");
    //console.log("../services/"+pair+"/"+Number(year-1)+".json")
    //const chart_feed = require("../services/"+pair+"/"+Number(year-1)+".json");
    // c_name = getCrypto(rand);
    //next_feed = require("../services/"+pair+"/"+Number(year)+".json");
    //getaws()
    //console.log("../services/"+pair+"/"+Number(year)+Number(month-1)+"EURUSD-M1-No Session"+".json")

    /*
    var chart_feed
    if (Number(month) === 1) {
      chart_feed = require("../services/"+pair+"/"+Number(year-1)+"12EURUSD-M1-No Session.json");
      next_feed = require("../services/"+pair+"/"+year+month+"EURUSD-M1-No Session.json");
    } else {
      chart_feed = require("../services/"+pair+"/"+year+Number(month-1)+"EURUSD-M1-No Session.json");
      next_feed = require("../services/"+pair+"/"+year+month+"EURUSD-M1-No Session.json");
      console.log(next_feed)
    }
    */
   
    var chart_feed
    if (Number(month) === 1) {
      await fetch(process.env.REACT_APP_AWSLINK+pair+"/"+year+"12EURUSD-M1-No%20Session.json",{
        method:"GET",
        mode: "cors",
        headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true}
      })
      .then(res => {if (res.status === 200) return res.json()})
      .then(data => {
          chart_feed = data
      })
      .catch(err=>{})
      await fetch(process.env.REACT_APP_AWSLINK+pair+"/"+year+month+"EURUSD-M1-No%20Session.json",{
        method:"GET",
        mode: "cors",
        headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true}
      })
      .then(res => {if (res.status === 200) return res.json()})
      .then(data => {
          next_feed = data
      })
      .catch(err=>{})
    } else {
      await fetch(process.env.REACT_APP_AWSLINK+pair+"/"+year+Number(month-1)+"EURUSD-M1-No%20Session.json",{
        method:"GET",
        mode: "cors",
        headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true}
      })
      .then(res => {if (res.status === 200) return res.json()})
      .then(data => {
          chart_feed = data
      })
      .catch(err=>{})
      await fetch(process.env.REACT_APP_AWSLINK+pair+"/"+year+month+"EURUSD-M1-No%20Session.json",{
        method:"GET",
        mode: "cors",
        headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true}
      })
      .then(res => {if (res.status === 200) return res.json()})
      .then(data => {
          next_feed = data
      })
      .catch(err=>{})
    }

    if (chart_feed.length) {
      var nchart_feed = chart_feed.concat(next_feed.slice(0, progrescounter))
      next_feed = next_feed.slice(progrescounter, next_feed.length - 1)

      if (first) {
        const lastBar = nchart_feed[nchart_feed.length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
      return nchart_feed;
    }

    /*

    const rand_om = Math.floor(Math.random() * 268);

    const chart_feed = require("../services/chart_data/" + rand_om + ".json");
    // c_name = getCrypto(rand);

    next_feed = require("../services/next_data/" + rand_om + ".json");

    if (chart_feed.length) {
      const json_data_st = JSON.stringify(chart_feed);
      const json_data = JSON.parse(json_data_st);
      
      periodParams.from = json_data[0].time;

      const bars = json_data.map((d) => ({
        time: d.time,
        low: d.low,
        high: d.high,
        open: d.open,
        close: d.close,
        volume: d.volume,
      }));
      console.log(bars)

      if (first) {
        const lastBar = bars[bars.length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
      return bars;
    }
    
    //const njsonData = Object.entries(jsonData).slice(0,99)
    //console.log(Object.entries(jsonData).slice(0,999))

    if (Object.keys(jsonData).length) {
      const bars = Object.entries(jsonData).map(([key, values]) => {
        const date = new Date(key);
        const timestampInMilliseconds = date.getTime();
        return {
            timestamp: timestampInMilliseconds,
            ...values
        };
      });
      console.log(bars)
    const njsonData = require('./testdata.json');
    
    const njsonData = require('./testdata.json');

    if (Object.keys(njsonData).length) {
      const bars = Object.entries(Object.entries(njsonData)).slice(0, 300).map(([key, value]) => {
        const date = new Date(value[0]);
        const timestampInMilliseconds = date.getTime();
        return {
          time: timestampInMilliseconds,
          low: value[1].Low,
          high: value[1].High,
          open: value[1].Open,
          close: value[1].Close,
        }
        //bars.push(tempbar)
      })
      //console.log(bars)
      periodParams.from = bars[0].time;
      next_feed = bars

      if (first) {
        const lastBar = bars[bars.length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
      return bars;
    }
    */
  },
};

export default historyProvider;
