import React, { useEffect, useState } from 'react';
import './main.css'
import darklogo from "./public/rapiddark.png"
//import 'chart.js/auto'
import { useParams } from 'react-router-dom';
import Analytics from './analytics';


export default function Dashboard({udata}) {
    const [gFormData, setgFormData] = useState({})
    const [sFormData, setsFormData] = useState({
        udata: udata,
        sname: '',
        sbalance: '',
        sstrategy: '',
        sasset: '',
        sdatestart: '',
        sdateend: ''
    })
    const { pid  } = useParams();
    
    const [sessionpopup, setsessionpopup] = useState(false)
    const [strategypopup, setstrategypopup] = useState(false)

    const [sessions, setsessions] = useState([])
    const [groups, setgroups] = useState([])

    const setdashboarddata = (e) => {
        window.location.pathname = "/dashboard/"+e.currentTarget.id
    }

    const logout = () => {
        fetch(process.env.REACT_APP_BACKEND+"/api/logout",{
            method:"GET",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
            window.location = "/login"
        }})
        .catch(err=>{})
    }

    const handleInputChange = (event) => {
        if (event.target.name === "syear") {
            let sdatestart = new Date(Number("20"+event.target.value), parseInt(sFormData?.smonth)-1, 1)
            let sdateend = new Date(Number("20"+event.target.value)+1, parseInt(sFormData?.smonth), 1)
            setsFormData({
                ...sFormData,
                "sdatestart": sdatestart,
                "sdateend": sdateend,
                [event.target.name]: event.target.value,
                udata: udata,
            });
            return;
        }
        if (event.target.name === "smonth") {
            let sdatestart = new Date(parseInt("20"+sFormData?.syear), Number(event.target.value)-1, 1)
            let sdateend = new Date(parseInt("20"+sFormData?.syear)+1, Number(event.target.value), 1)
            setsFormData({
                ...sFormData,
                "sdatestart": sdatestart,
                "sdateend": sdateend,
                [event.target.name]: event.target.value,
                udata: udata,
            });
            return;
        }
        setsFormData({
            ...sFormData,
            [event.target.name]: event.target.value,
            udata: udata,
        });
    };
    const handlegformdata = (event) => {
        setgFormData({
            ...gFormData,
            [event.target.name]: event.target.value,
            udata: udata,
        });
    };
    /*
    const handleeditoperation = (id) => {

    }
    const handleduplicateoperation = (id) => {

    }
    */
    const handledeleteoperation = (id, e) => {
        e.stopPropagation()
        fetch(process.env.REACT_APP_BACKEND+"/api/removesession",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({sid: sessions[id].sid})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            console.log(data.mess)
        })
        .catch(err=>{})
        const filteredItems = sessions.filter((item, idx) => idx !== id);
        setsessions(filteredItems);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_BACKEND+"/api/newsession",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({data: sFormData})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setsessions([...sessions, data.data])
                setsFormData({
                    udata: udata,
                    sname: '',
                    sbalance: '',
                    sstrategy: '',
                    sasset: '',
                    syear: '',
                    //sdatestart: '',
                    //sdateend: ''
                })
                setsessionpopup(false)
            }
            //console.log(data.mess)
        })
        .catch(err=>{})
    }
    const newGroup = async (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_BACKEND+"/api/newgroup",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({data: gFormData})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setgroups([...groups, data.data])
                setstrategypopup(false)
            }
        })
        .catch(err=>{})
    }
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        fetch(process.env.REACT_APP_BACKEND+"/api/getsessions",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({user: udata}),
        }, { signal })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setsessions(data.sdata)
                setgroups(data.gdata)
            }
        })
        .catch(err=>{})
        
        return () => {
          abortController.abort();
        };
    }, [])

    const resetpass = (e) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_BACKEND+"/api/setnewpassword",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({udata: udata, newpass: e.target.elements[0].value})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setsessions([...sessions, data.data])
            }
        })
        .catch(err=>{})
    }

    return (
        <div className="dashboard-cont">
            <div className="menu-cont">
                <div className="menu-split">
                    <img src={darklogo} alt="" className="menulogo" />
                    <hr className="menusplitline" />
                    <h1 className="menupage active-page" id='session' onClick={(e) => setdashboarddata(e)}>Sessions</h1>
                    <hr className="menusplitline" />
                    {
                        groups.map((it, id) => {
                            return (
                                <h1 className="menupage" key={id} id={it.gid} onClick={(e) => setdashboarddata(e)}>{it.gname}</h1>
                            )
                        })
                    }
                    <button className="addnewstratbtn" id='addstrategy' onClick={(e) => {setstrategypopup(true)}}>Add Group</button>
                    <hr className="menusplitline" />
                </div>
                <button className="addnewstratbtn" id='account' onClick={(e) => setdashboarddata(e)}>Account</button>
            </div>
            {pid === "session" || pid === undefined ?
            <div className="sessionslist">
                <table className="sessions-table">
                    <tr>
                        <th><button className="create-session-btn" onClick={() => {setsessionpopup(true)}}>Create session</button></th>
                        <th>Name</th>
                        <th>Balance</th>
                        <th>Group</th>
                        <th>Pair</th>
                        <th>Period</th>
                        <th></th>
                    </tr>
                    {
                        sessions.map((it, id) => {
                            let startd = new Date(it.sdatestart)
                            let endd = new Date(it.sdateend)
                            let gname = ""

                            groups.forEach(g => {
                                if (g.gid === it.sstrategy) {
                                    gname = g.gname
                                }
                            });

                            return (
                                <tr key={id} id={it.sid} onClick={(e) => setdashboarddata(e)}>
                                    <td><a href={'/chart/'+it.sid}className="play-session-btn">Continue</a></td>
                                    <td>{it.sname}</td>
                                    <td>${(it.sbalancecurrent).toFixed(2)}</td>
                                    <td>{gname}</td>
                                    <td>{it.sasset}</td>
                                    <td>{startd.getDate()+"/"+(startd.getMonth() + 1)+"/"+startd.getFullYear()+" - "+endd.getDate()+"/"+(endd.getMonth() + 1)+"/"+endd.getFullYear()}</td>
                                    <td className='options-td'>
                                        <button className='sessions-info-btn'>Options</button>
                                        <ul className='options-dropdown'>
                                            <li>Edit</li>
                                            <hr />
                                            <li onClick={handledeleteoperation.bind(this, id)} >Delete</li>
                                            <hr />
                                            <li>Duplicate</li>
                                            <hr />
                                            <li>Analytics</li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
                <div className="session-create-popup-bg" style={sessionpopup ? {display:'flex'} : {display:'none'}} onClick={(e) => {if (e.target === e.currentTarget) {setsessionpopup(false)}}}>
                    <form className="session-create-popup" onSubmit={handleSubmit}>
                        <div className="session-create-popup-topbar">
                            <h1 className="popup-title">New Session</h1>
                            <img className='popup-exit' src="/" alt="" />
                        </div>
                        <p className="input-title">Session Name *</p>
                        <input type="text" className="popup-input" name='sname' onChange={handleInputChange}/>
                        <p className="input-title">Balance (USD) *</p>
                        <input type="number" className="popup-input" name='sbalance' onChange={handleInputChange}/>
                        <p className="input-title">Group (optional)</p>
                        <select className='popup-select' name='sstrategy' onChange={handleInputChange}>
                            <option value="" style={{display: 'none'}}></option>
                            {
                                groups.map((it, id) => {
                                    return (
                                        <option value={it.gid}>{it.gname}</option>
                                    )
                                })
                            }
                        </select>
                        <p className="input-title">Pair *</p>
                        <select className='popup-select' name='sasset' onChange={handleInputChange}>
                            <option value="" style={{display: 'none'}}></option>
                            <option value="EURUSD">EUR/USD</option>
                        </select>
                        <p className="input-title">Year *</p>
                        <select className='popup-select' name='syear' onChange={handleInputChange}>
                            <option value="" style={{display: 'none'}}></option>
                            <option value="16">2016</option>
                            <option value="17">2017</option>
                            <option value="18">2018</option>
                            <option value="19">2019</option>
                            <option value="20">2020</option>
                            <option value="21">2021</option>
                            <option value="22">2022</option>
                            <option value="23">2023</option>
                            <option value="24">2024</option>
                        </select>
                        <p className="input-title">Month *</p>
                        <select className='popup-select' name='smonth' onChange={handleInputChange}>
                            <option value="" style={{display: 'none'}}></option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        {/* <p className="input-title">Start Date</p>
                        <input type="date" min="2018-01-01" max="2024-12-31" className="popup-input" name='sdatestart' onChange={handleInputChange}/>
                        <p className="input-title">End Date</p>
                        <input type="date" min="2018-01-01" max="2024-12-31" className="popup-input" name='sdateend' onChange={handleInputChange}/> */}
                        <div className="popup-actions">
                            <button type='button' className="popup-cancel" onClick={(e) => {setsessionpopup(false)}}>Cancel</button>
                            <button type='submit' className="popup-Accept">Create</button>
                        </div>
                    </form>
                </div>
            </div>
            : pid === "account" ?
            <div className="accont-cont">
                <div className="account-details-cont">
                    <h1 className="account-page-header">Account</h1>
                    <p className='acc-details-tag'>Email</p>
                    <input className='acc-details' type="text" name='email' value={udata ? udata : ""} disabled/>
                    <p className='acc-details-tag'>Password</p>
                    <input className='acc-details' type="password" name='password' value={"password is 123"} disabled/>
                    <form onSubmit={resetpass}>
                        <p className='acc-details-tag'>New Password</p>
                        <input className='acc-details' type="password" name='npassword'/>
                        <div className="account-bottom-items">
                            <button type='submit' className="reset-password-btn">Reset Password</button>
                            <a href='/login' onClick={logout} className="logout-btn">Log Out</a>
                        </div>
                    </form>
                </div>
                <div className="account-details-cont">
                    <h1 className="account-page-header">More comming soon!</h1>
                    {/* <h1 className="account-page-header">Billing</h1>
                    <p className="billing-section-title">Plans:</p>
                    <div className="billing-plans">
                        <div className="billing-plan-option">
                            <p className="billing-price-info">$25,00/mo</p>
                            <p className="billing-price-info">Next payment: 11.09.2023</p>
                        </div>
                        <hr className='billing-hr'/>
                        <div className="billing-plan-option">
                            <p className="billing-price-info">$250,00/y</p>
                            <p className="billing-price-info">Change</p>
                        </div>
                    </div>
                    <p className="billing-section-title">Payment method:</p>
                    <div className="payment-options-cont">
                        <p className="payment-type">Card</p>
                        <div className="payment-options-options">
                            <p className="payment-cancel-plan">Cancel</p>
                        </div>
                    </div>
                    <p className="billing-section-title">Payment history:</p> */}
                </div>
            </div>
            : <Analytics sessions={sessions} groups={groups}/>
            }
            {strategypopup ?
                <div className="strategy-create-popup-bg" onClick={(e) => {if (e.target === e.currentTarget) {setstrategypopup(false)}}}>
                    <form className="session-create-popup" onSubmit={newGroup}>
                        <div className="session-create-popup-topbar">
                            <h1 className="popup-title">New Group</h1>
                            <img className='popup-exit' src="/" alt="" />
                        </div>
                        <p className="input-title">Group Name</p>
                        <input type="text" className="popup-input" name='gname' onChange={handlegformdata}/>
                        <p className="input-title">Group Description</p>
                        <textarea className='popup-input-area' id="" cols="30" rows="10" name='gdescription' onChange={handlegformdata}></textarea>

                        <div className="popup-actions">
                            <button type='button' className="popup-cancel" onClick={(e) => {setstrategypopup(false)}}>Cancel</button>
                            <button type='submit' className="popup-Accept">Create</button>
                        </div>
                    </form>
                </div>
            : ""}
        </div>
    )
}