import React, { useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { AppContext } from './appcontent';
import ReactGA from 'react-ga4';
import Landingp from './lp';
import Login from './login';
import Session from './session';
import Dashboard from './dashboard';

// competitors
//https://traderbuddy.net/home
ReactGA.initialize('G-8RFT37RJNL');

export default function App() {
    //const [udata, setUdata] = useContext(AppContext);
    const [udata, setudata] = useState()
    
    useEffect(() => {
        ReactGA.send({
            hitType: "pagewiev",
            page: window.location.pathname
        })
        fetch(process.env.REACT_APP_BACKEND+"/api/auth",{
            method:"GET",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                setudata(data.data)
                if (window.location.pathname === "/login") {
                    window.location = "/dashboard"
                }
            }
            else {
                if (window.location.pathname === "/dashboard" || window.location.pathname === "/chart") {
                    window.location = "/login"
                }
            }
        })
        .catch(err=>{})
    }, [])
    

    return (
    <AppContext.Provider value={udata}>
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Landingp />}></Route>
            <Route path='/login/:email' element={<Login/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/dashboard/:pid' element={<Dashboard udata={udata?.email ?? ""}/>}></Route>
            <Route path='/dashboard' element={<Dashboard udata={udata?.email ?? ""}/>}></Route>
            <Route path='/chart/:sid' element={<Session />}></Route>
        </Routes>
    </BrowserRouter>
    </AppContext.Provider>
    )
}