import { useEffect, useRef, useState } from "react";
 import { pair } from "./session";


import Datafeed from "./datafeed/datafeed";
import { _lastbar, nextDay } from "./datafeed/stream";
import { next_feed } from "./datafeed/historyProvider";
import { widget } from "./charting_library";
import ReactGA from 'react-ga4';

export let tvWidget;
export let progrescounter = 0;
export let token_name = null;

const Game = ({ handlenewprice, currentprogres, sendable, setsendable, sid, setoside, setoentry, setosl, setotp }) => {
  
  //datecontroll
  const [slidervalue, setslidervalue] = useState(10)
  const [slidertf, setslidertf] = useState(1)
  const floatcontrolsref = useRef(null)
  const cords = useRef({startx: 0, starty: 0, lastx: 400, lasty: 400})
  const isclicked = useRef(false)
  //cnadles controls
  const [runtime, setruntime] = useState(false)
  //const curcandle = useRef(0)
  //const chartref = useRef()
  //newbar controls
  const currentBar = useRef(null);
  const progrescount = useRef(0);
  const [sessionend, setsessionend] = useState(false)
  /*
  //const counter = useRef(0);
  const tradeHistory = useRef([]);
  const withPosition = useRef({
    status: false,
    desc: null,
  });
  const position = useRef(null);
  const leverage = useRef(1);
  const positionSize = useRef(100000);
  const totalProfit = useRef(0);
  const positionDays = useRef(0);
  const winRate = useRef(0);
  */
  const ref = useRef();
  useEffect(() => {

    const getLanguageFromURL = () => {
      const regex = new RegExp("[\\?&]lang=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? null
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    
    if (pair) {
      const widgetOptions = {
        symbol: pair,
        datafeed: Datafeed,
        interval: "240",
        container: "tv",
        timeframe: "15D",
        library_path: "/charting_library/",
        locale: getLanguageFromURL() || "en",
        clientId: "test",
        userId: "public_user_id",
        fullscreen: false,
        autosize: true,
        theme: "Dark",
        timezone: "UTC",
        // custom_css_url: "chart.css",
        enabled_features: [
          "fix_left_edge",
          "chart_scroll",
          "chart_zoom",
          "header_undo_redo",
          "header_screenshot",
          "header_resolutions",
          "main_series_scale_menu",
          "header_chart_type",
          "header_settings",
          "use_localstorage_for_settings",
          "header_fullscreen_button",
          "control_bar",
          "context_menus",
        ],
        disabled_features: [
          "header_symbol_search",
          "symbol_search_hot_key",
          "header_compare",
          "go_to_date",
          "timezone_menu",
          "symbol_info",
          "adaptive_logo",
          "display_market_status",
          "border_around_the_chart",
          "timeframes_toolbar",
          "scales_date_format",
          "source_selection_markers",
          "create_volume_indicator_by_default",
        ],
        custom_css_url: "../../assets/style.css",
        overrides: {
          "paneProperties.horzGridProperties.color": "rgba(0, 0, 0, 0)",
          "paneProperties.vertGridProperties.color": "rgba(0, 0, 0, 0)",
        },
      };
      
      tvWidget = new widget(widgetOptions);  
    }
  }, [pair]);

  useEffect(() => {
    ReactGA.event({
      category: "Session",
      action: "Session Open",
    });
  }, [])
  
  useEffect(() => {
    progrescounter = currentprogres
  }, [currentprogres])
  /*
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
  };
  useEffect(() => {
    if (runtime) {
      const intervalId = setInterval(() => {
        var odata = []
        var postype = []
        tvWidget.activeChart().getAllShapes().forEach(s => {
          if (s.name === "long_position") {
            odata.push(s.id)
            postype.push(s.name)
          } if (s.name === "short_position") {
            odata.push(s.id)
            postype.push(s.name)
          }
        });
        if (odata.length > 0) {
          setodata({
            side: postype[odata.length-1] === "long_position" ? "buy" : "sell",
            price: parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._entryPriceAxisView._axisRendererData.text, getCircularReplacer(), 2),
            tp: parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._profitPriceAxisView._axisRendererData.text, getCircularReplacer(), 2),
            sl: parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._stopPriceAxisView._axisRendererData.text, getCircularReplacer(), 2)
          })
        }

        nextDay(next_feed[counterRef.current]);
        counterRef.current += 1;
        setCurrentBar(next_feed[counterRef.current]);
      }, Number(slidervalue) * 100);
  
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runtime, slidervalue])
  */

  const showNextDay = () => {
    //var forprint = tvWidget.activeChart().createOrderLine().setDirection("buy").setPrice(1.0869).setQuantity(5).setText("Entry")
    //console.log(forprint.getCancelTooltip())
    if (_lastbar === undefined) {
      setruntime(false)
      setsessionend(true)
      return
    }
    handlenewprice(_lastbar)
    nextDay(next_feed[progrescount.current]);
    progrescount.current += 1;
    progrescounter += 1;
    currentBar.current = next_feed[progrescount.current];
    
    tvWidget.activeChart().getAllShapes().forEach(s => {
      if (s.name === "long_position") {
        setoside("buy")
        setoentry(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._entryPriceAxisView._axisRendererData.text))
        setosl(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._stopPriceAxisView._axisRendererData.text))
        setotp(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._profitPriceAxisView._axisRendererData.text))
      } if (s.name === "short_position") {
        setoside("sell")
        setoentry(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._entryPriceAxisView._axisRendererData.text))
        setosl(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._stopPriceAxisView._axisRendererData.text))
        setotp(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._profitPriceAxisView._axisRendererData.text))
      }
    });

    /*
    var odata = []
    var postype = []
    tvWidget.activeChart().getAllShapes().forEach(s => {
      if (s.name === "long_position") {
        odata.push(s.id)
        postype.push(s.name)
        console.log(s)
      } if (s.name === "short_position") {
        odata.push(s.id)
        postype.push(s.name)
        console.log(s)
      }
    });

    if (odata.length > 0) {
      var side = postype[odata.length-1] === "long_position" ? "buy" : "sell"
      var price = parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._entryPriceAxisView._axisRendererData.text)
      var tp = parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._profitPriceAxisView._axisRendererData.text)
      var sl = parseFloat(tvWidget.activeChart().getShapeById(odata[odata.length-1])._source._stopPriceAxisView._axisRendererData.text)
      setoFormData({
        ...oFormData,
        otype: side,
        oprice: price,
        otp: tp,
        osl: sl,
      });
      var otype = document.querySelector('select[name="otype"]')
      var priceinput = document.querySelector('input[name="oprice"]')
      var tpinput = document.querySelector('input[name="otp"]')
      var slinput = document.querySelector('input[name="osl"]')
      if (priceinput !== null) {
          otype.value = side
          priceinput.value = price
          slinput.value = sl
          tpinput.value = tp
      }
    }
    */
  };
  
  useEffect(() => {
    if (runtime) {
      setsendable(true)
      const intervalId = setInterval(() => {
        showNextDay()
      }, Number(slidervalue) * 100 / slidertf);
  
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runtime, slidervalue])

  const updatedate = (lastcandle) => {
    fetch(process.env.REACT_APP_BACKEND+"/api/updatelastsessionprice",{
        method:"POST",
        credentials:"include",
        mode: "cors",
        headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
        body: JSON.stringify({sid: sid, lastcandle: lastcandle})
    })
    .then(res => {if (res.status === 200) return res.json()})
    .then(data => {
        //console.log(data.mess)
    })
    .catch(err=>{})
  }
  
  useEffect(() => {
    if (!runtime) {
      updatedate(progrescounter)
    }
  }, [runtime])

  useEffect(() => {
    setruntime(false)
  }, [sendable])
  

  const mousedown = (e) => {
      e.target.style.width = "200px"
      e.target.style.height = "200px"
      e.target.style.transform = "translate(-50%, -50%)"
      isclicked.current = true
      cords.current.startx = e.clientX
      cords.current.starty = e.clientY
  }
  const mouseup = (e) => {
      e.target.style.width = "40px"
      e.target.style.height = "40px"
      e.target.style.transform = "translate(0, -50%)"
      isclicked.current = false
      cords.current.lasty = floatcontrolsref.current.offsetTop
      cords.current.lastx = floatcontrolsref.current.offsetLeft
  }
  const mousemove = (e) => {
      if (!isclicked.current) return;

      const nextx = e.clientX - cords.current.startx + cords.current.lastx
      const nexty = e.clientY - cords.current.starty + cords.current.lasty

      floatcontrolsref.current.style.top = String(nexty)+"px"
      floatcontrolsref.current.style.left = String(nextx)+"px"
  }

  return (
    <>
    <div className="chart-cont" id="tv" ref={ref} style={{ height: "calc(100vh - 60px)", minHeight: "calc(100vh - 60px)"}}></div>
      <div ref={floatcontrolsref} className="float-controls-cont">
        {/* <img src="" alt="" className="float-dragbtn" /> */}
        <div className="float-dragbtn" onMouseDown={(e) => mousedown(e)} onMouseUp={(e) => mouseup(e)} onMouseMove={(e) => mousemove(e)} onMouseLeave={mouseup}></div>
        <p className="float-text">&#9776;</p>
        <p className="float-number">{slidervalue}</p>
        <input type="range" className='float-slider' min={1} max={10} value={slidervalue} onChange={(e) => setslidervalue(e.target.value)}/>
        <select className="float-tf-selector" name="tf" id="tf" onChange={(e) => setslidertf(e.target.value)}>
          <option value="1">1m</option>
          <option value="15">15m</option>
          <option value="60">1h</option>
          <option value="240">4h</option>
        </select>
        <p className="float-number" onClick={() => {setruntime(prevState => !prevState)}}>{runtime ? <span>&#9868;</span> : <span>&#10148;</span>}</p>
      </div>
      {
        sessionend ? 
        <div className="session-end-bg">
          <div className="session-end-card">
            <h1 className="session-end-heading">This Session has ended</h1>
            <a href={"/dashboard/"+sid} className="session-end-link">Analytics</a>
            <a href="/dashboard" className="session-end-link">Dashboard</a>
          </div>
        </div>
        : ""
      }
    </>
  );
};

export default Game;
