import React, { useEffect, useRef, useState } from 'react';
import './main.css'
import darklogo from "./public/rapiddark.png"
import * as echarts from 'echarts';
import { SVGRenderer } from 'echarts/renderers';
import icon1 from "./public/1lpicon.svg";
import icon2 from "./public/2lpicon.svg";
import icon3 from "./public/3lpicon.svg";
import icon4 from "./public/4lpicon.svg";
//import icon1 from "./public/1lpicon.svg";

export default function Landingp() {
    const [fvalue, setfvalue] = useState()
    const [dummydates] = useState([
        5000,
        5069.62,
        5099.04,
        5083.07,
        5130.62,
        5189.04,
        5170.07,
    ])
    const [dummydata] = useState([
        "23:53 1 Jan 2024",
        "23:53 1 Jan 2024",
        "1:19 2 Jan 2024",
        "1:49 2 Jan 2024",
        "13:53 1 Jan 2024",
        "15:19 2 Jan 2024",
        "8:49 2 Jan 2024",
    ])
    const visualdemoref = useRef()

    useEffect(() => {
        setTimeout(() => {
            if (visualdemoref.current && document.getElementById("visual-demo") !== null) {
                
                echarts.use([SVGRenderer]);
                var myChart = echarts.init(document.getElementById("visual-demo"), null, { renderer: 'svg' }); 
                var option;
                
                window.addEventListener('resize', function() {
                  myChart.resize();
                });
                
                option = {
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      animation: false,
                      type: 'cross',
                      lineStyle: {
                        color: '#E9F2FF',
                        width: 1,
                        opacity: 1
                      }
                    }
                  },
                  xAxis: {
                    type: 'category',
                    show: false,
                    data: dummydata,
                    boundaryGap: false,
                    axisLine: { lineStyle: { color: '#8392A5' } }
                  },
                  yAxis: {
                    scale: true,
                    axisLine: { lineStyle: { color: '#8392A5' } },
                    splitLine: { show: false }
                  },
                  grid: {
                    show: false,
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    tooltip: {
                      show: true,
                      axisPointer: {
                        type: "none",
                      },
                      trigger: 'axis',
                    }
                  },
                  series: [
                    {
                        name: 'Balance',
                        type: 'line',
                        smooth: true,
                        lineStyle: {
                          width: 1
                        },
                        showSymbol: false,
                        areaStyle: {
                          opacity: 0.8,
                          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                              offset: 0,
                              color: '#1D7AFC'
                            },
                            {
                              offset: 1,
                              color: '#1D7AFC00'
                            }
                          ])
                        },
                        emphasis: {
                          focus: 'series'
                        },
                        data: dummydates
                      }
                  ]
                };
                
                option && myChart.setOption(option);
                visualdemoref.current = myChart
            }
        }, 500);
    }, [dummydata, dummydates])

    return (
        <>
        <div className="header-cont">
            <img src={darklogo} alt="" className="header-logo" />
            {/* <div className="header-menu">
                <a href="#visual-demo-chart" className="header-menu-item">Analytics</a>
                <a href="/" className="header-menu-item">Features</a>
                <a href="/" className="header-menu-item">FaQ</a>
                <a href="/" className="header-menu-item">Contact</a>
            </div> */}
            {/* <p>Take advantage of the opportunity and use it while it's still free.</p> */}
            <p>🎁 FREE for a limited time</p>
            <button className="header-actionbtn" onClick={() => {window.location = "/login"}}>Log In</button>
        </div>

        <div className="hero-cont">
            <h1 className="hero-main-text">LEARN FOREX</h1>
            <h1 className="hero-main-text" style={{color:"#1D7AFC"}}>THE RIGHT WAY</h1>
            <p className="hero-sub-text">Backtest trading strategies, analyze your performance and improve.</p>
            <div className="hero-action-cont">
                <input type="email" name='email' onKeyUp={(e) => {setfvalue(e.target.value)}} placeholder='E-mail' className='hero-form-input'/>
                <button className="hero-form-submit" type='submit'  onClick={() => {window.location = "/login/"+fvalue}}>Start Now</button>
            </div>
            {/* <p className="hero-sub-text">Sign up for 1 FREE month!</p> */}
            {/* <p className="hero-sub-text">FREE FOR A LIMITED TIME ONLY</p> */}
        </div>

        <section className="visual-cont">
            <div className="visual-demo-chart" id='visual-demo-chart'>
                <div className="analytics-main-metrics">
                    <div className="analytics-main-segment">
                        <div className="a-m-s-top">
                            <img src="/" alt="" />
                            <p className="a-m-title">Account Balance:</p>
                        </div>
                        <div className="a-m-s-bot">
                            <p className="a-m-value">${(5170.07).toFixed(2)}</p>
                            <p className={(3.40).toFixed(2) > 0 ? "a-m-percentage resgreen" : "a-m-percentage resred"}>{(3.40).toFixed(2)}%</p>
                        </div>
                    </div>
                    <div className="vl"></div>
                    <div className="analytics-main-segment">
                        <div className="a-m-s-top">
                            <img src="/" alt="" />
                            <p className="a-m-title">Total PnL:</p>
                        </div>
                        <div className="a-m-s-bot">
                            <p className="a-m-value">${(170.07).toFixed(2)}</p>
                        </div>
                    </div>
                    <div className="vl"></div>
                    <div className="analytics-main-segment">
                        <div className="a-m-s-top">
                            <img src="/" alt="" />
                            <p className="a-m-title">Average RR:</p>
                        </div>
                        <div className="a-m-s-bot">
                            <p className="a-m-value">{(3.5).toFixed(2)}</p>
                            {/* <p className="a-m-percentage resgreen">-2,12%</p> */}
                        </div>
                    </div>
                    <div className="vl"></div>
                    <div className="analytics-main-segment">
                        <div className="a-m-s-top">
                            <img src="/" alt="" />
                            <p className="a-m-title">Win Rate:</p>
                        </div>
                        <div className="a-m-s-bot">
                            <p className="a-m-value">{(66.66).toFixed(2)}%</p>
                            {/* <p className="a-m-percentage resred">-2,12%</p> */}
                        </div>
                    </div>
                    <div className="vl"></div>
                    <div className="analytics-main-segment">
                        <div className="a-m-s-top">
                            <img src="/" alt="" />
                            <p className="a-m-title">Trade Count:</p>
                        </div>
                        <div className="a-m-s-bot">
                            <p className="a-m-value">{6}</p>
                            <p className="a-m-percentage resgreen">4/2</p>
                        </div>
                    </div>
                </div>
                <div className="visual" id='visual-demo' ref={visualdemoref}></div>
            </div>
            <div className="visual-bgchange"></div>
        </section>
        <section className="explanation-cont">
          <h1 className='section-title'>Take your skills to <span style={{color: "#1D7AFC"}}>NEXT LEVEL</span></h1>
          <div className="explanation-step">
            <div className="explanation-step-text">
              <h1 className="explanation-title">TEST PERFORMANCE</h1>
              <p className="explanation-p">Your found a strategy. Great! But how can you be sure that it can be profitable? You either test it on a demo account for months, or use RapidFx and speed up the process. Discover your next setup opportunity in seconds by replaying historical data, rather than waiting for market conditions on a demo account.</p>
            </div>
              <div className="ex-demo-cont">
                <form className="session-create-popup" onSubmit={(event) => {event.preventDefault()}} style={{zIndex: "9"}}>
                    <div className="session-create-popup-topbar">
                        <h1 className="popup-title">New Session</h1>
                    </div>
                    <p className="input-title">Session Name</p>
                    <input type="text" className="popup-input" name='sname' disabled/>
                    <p className="input-title">Balance (USD)</p>
                    <input type="number" className="popup-input" name='sbalance' disabled/>
                    <p className="input-title">Pair</p>
                    <select className='popup-select' name='sasset' disabled>
                        <option value="EUR/USD">EUR/USD</option>
                        <option value="GBP/USD">GBP/USD</option>
                        <option value="USD/JPY">USD/JPY</option>
                    </select>
                    <p className="input-title">Strategy (optional)</p>
                    <select className='popup-select' name='sstrategy' disabled>
                      
                    </select>
                    <p className="input-title">Start Date</p>
                    <input type="text" min="2018-01-01" max="2024-12-31" className="popup-input" disabled name='sdatestart'/>
                    <p className="input-title">End Date</p>
                    <input type="text" min="2018-01-01" max="2024-12-31" className="popup-input" disabled name='sdateend'/>
                    <div className="popup-actions">
                        <button type='button' className="popup-cancel">Cancel</button>
                        <button type='submit' className="popup-Accept">Create</button>
                    </div>
                </form>
              </div>
          </div>
          <div className="explanation-step">
              <div className="ex-demo">
                  <table className="a-t-table2">
                      {/* <tr>
                          <td>Sharpe ratio</td>
                          <td>answer</td>
                      </tr> */}
                      <tr>
                          <td>Profit factor</td>
                          <td>{(2).toFixed(2)}</td>
                      </tr>
                      <tr>
                          <td>Recovery factor</td>
                          <td>{(40).toFixed(2)}</td>
                      </tr>
                      <tr>
                          <td>Expectancy</td>
                          <td>{(125).toFixed(2)}</td>
                      </tr>
                      <tr>
                          <td>Annualized return</td>
                          <td>{(200).toFixed(2)}%</td>
                      </tr>
                      <tr>
                          <td>Average risk reward</td>
                          <td>{(6).toFixed(2)}</td>
                      </tr>
                  </table>
              </div>
            <div className="explanation-step-text">
              <h1 className="explanation-title">ANALYZE RESULTS</h1>
              <p className="explanation-p">Once you gather enought data, you can analyze your results in the dashboard with advanced analytics and metrics. Graphical visualisations will help you understand the data. Our goal is to provide you with as much information as possible for you to really understand your strategy performance.</p>
            </div>
          </div>
          <div className="explanation-step">
            <div className="explanation-step-text">
              <h1 className="explanation-title">IMPROVE & ADJUST</h1>
              <p className="explanation-p">After a couple of backtesting sessions you can ensure yourself about the profitablity of your strategy. If the results wouldn't be satisfying, you have a lot of data to make any adjustments to the strategy. There is no limit of active sessions, so test as much as you want.</p>
            </div>
              <div className="ex-demo-cont">
                <div className="ex-order-demo" style={{width: "300px", borderRadius: "20px", padding: "2rem"}}>
                    <div className="order-box-header">
                        <p className="float-text">&#9776;</p>
                        <p className='order-box-close'>&#10006;</p>
                    </div>
                    <form id='orderform' onSubmit={(event) => {event.preventDefault()}} className="order-form">
                        <label htmlFor="otype">Order type</label>
                            <select required className='order-input' name='otype' disabled>
                                <option value="buy">Buy</option>
                                <option value="sell">Sell</option>
                                <option value="buylimit">Buy Limit</option>
                                <option value="selllimit">Sell Limit</option>
                                <option value="buystop">Buy Stop</option>
                                <option value="sellstop">Sell Stop</option>
                            </select>
                        <label htmlFor="oprice">Entry Price</label>
                        <input required name='oprice' type="number" step={0.00001} className="order-input" disabled/>
                        <label htmlFor="ovolume">Volume (lots)</label>
                        <input required name='ovolume' type="number" step={0.01} className="order-input" disabled />
                        <label htmlFor="osl">Stop Loss</label>
                        <input required name='osl' type="number" step={0.00001} className="order-input" disabled/>
                        <label htmlFor="otp">Take Profit</label>
                        <input required name='otp' type="number" step={0.00001} className="order-input" disabled/>
                    </form>
                    <button type='submit' form='orderform' className="order-form-btn">Submit</button>
                </div>
              </div>
            {/* <img src="/" alt="" className="explanation-img" /> */}
          </div>
          <div className="bg-gradient-color" style={{height: "350px", width: "350px", top: "350px", left: "-60px"}}></div>
          <div className="bg-gradient-color" style={{height: "350px", width: "350px", top: "140px", left: "90vw"}}></div>
          <div className="bg-gradient-color" style={{height: "350px", width: "350px", top: "900px", left: "50vw"}}></div>
        </section>
        <section className="benefits-cont">
          <div className="benefits-section">
            <h1 className='section-title'><span style={{color: "#1D7AFC"}}>FREE</span> for a limited time only</h1>
            <div className="benefits-list">
              <div className="benefit-item">
                <img src={icon2} alt="" className="benefit-img" />
                <h1 className="benefit-text">Free Access</h1>
              </div>
              <div className="benefit-item">
                <img src={icon3} alt="" className="benefit-img" />
                <h1 className="benefit-text">Deep Analytics</h1>
              </div>
              <div className="benefit-item">
                <img src={icon1} alt="" className="benefit-img" />
                <h1 className="benefit-text">Simple To Use</h1>
              </div>
              <div className="benefit-item">
                <img src={icon4} alt="" className="benefit-img" />
                <h1 className="benefit-text">Recomended</h1>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="faq-cont">
          <div className="faq">
            <h1 className='section-title'><span style={{color: "#1D7AFC"}}>FaQ</span></h1>
          </div>
        </section> */}
        <section className="footer-cont">
          <div className="footer">
            <div className="footer-left">
              <img src={darklogo} alt="" className="footer-logo" />
            </div>
            <div className="right-footer-cont">
              <div className="hero-action-cont" style={{marginTop: "0"}}>
                  <input style={{backgroundColor: "#171717"}} type="email" name='email' onKeyUp={(e) => {setfvalue(e.target.value)}} placeholder='E-mail' className='hero-form-input'/>
                  <button className="hero-form-submit" type='submit'  onClick={() => {window.location = "/login/"+fvalue}}>Start Now</button>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}