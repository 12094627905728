import React, { useEffect, useRef, useState } from 'react';
import './main.css'
import logo from "./public/rapidlogonobg.png"
import { useParams } from 'react-router-dom';
import { tvWidget } from './seschart';
import Game from './seschart';

export var pair = ""
export var year = 23
export var month = 2

export default function Session() {
    //datecontroll
    const floatcontrolsref = useRef(null)
    const cords = useRef({startx: 300, starty: 300, lastx: 0, lasty: 0})
    const isclicked = useRef(false)
    const ref = useRef()

    const { sid  } = useParams();
  
    //const [orderonchart, setorderonchart] = useState({})
    const [currentPrice, setCurrentPrice] = useState()
    //const [currentDate, setCurrentDate] = useState()
    const [sessiondata, setsessiondata] = useState({operations: []})
    const [accbalance, setaccbalance] = useState()
    const [orderbox, setorderbox] = useState(false)
    //const [positioneditbox, setpositioneditbox] = useState(false)
    const [positioneditdata, setpositioneditdata] = useState(-1)


    const [orderlist, setorderlist] = useState([])
    const [oFormData, setoFormData] = useState({
        otype: "buy",
        oprice: '',
        ovolume: '',
        osl: '',
        otp: '',
    })

    const [oside, setoside] = useState()
    const [oentry, setoentry] = useState(0)
    const [ovolume, setovolume] = useState()
    const [osl, setosl] = useState()
    const [otp, setotp] = useState()

    const [pnl, setpnl] = useState(0)
    const [upnl, setupnl] = useState(0)
    
    const [positionslist, setpositionslist] = useState([])
    const [historylist, sethistorylist] = useState([])
    const [sendable, setsendable] = useState(true)
    
    const updatehistory = (op) => {
        fetch(process.env.REACT_APP_BACKEND+"/api/updateorderhistory",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({sid: sid, operation: op})
        })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            //console.log(data)
        })
        .catch(err=>{})
    }
    

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        fetch(process.env.REACT_APP_BACKEND+"/api/getpositionhistory",{
            method:"POST",
            credentials:"include",
            mode: "cors",
            headers:{Accept: "application/json","Content-type": "application/json","Access-Control-Allow-Credentials": true},
            body: JSON.stringify({sid: sid})
        }, { signal })
        .then(res => {if (res.status === 200) return res.json()})
        .then(data => {
            if (data.valid) {
                pair = data.data.sasset
                year = data.data.syear
                month = data.data.smonth
                setsessiondata(data.data)
                setaccbalance((data.data.sbalancecurrent).toFixed(2))
                setpnl((data.data.sbalancecurrent - data.data.sbalancestart).toFixed(2))
        }})
        .catch(err=>{})
        
        return () => {
          abortController.abort();
        };
    }, [sid])

        /*
    const handlenewprice = (value) => {
        tvWidget.activeChart().getAllShapes().forEach(shape => {
            console.log(tvWidget.activeChart().getShapeById(shape.id))
        });
        
        //console.log(tvWidget.activeChart())
        //console.log(tvWidget.activeChart().createOrderLine().setCancelTooltip(true))
        setCurrentPrice(value);
    }*/
    
    const handlenewprice = (value) => {
        orderlist.forEach((order, id) => {
            if (order.oprice >= value.low && order.oprice <= value.high) {
                var newpos = orderlist[id]
                let pipvalue = 0.0001/value.close*newpos.ovolume*100000
                newpos.slvalue = parseFloat(-1 * (Math.abs(newpos.oprice - newpos.osl) * pipvalue * 1000).toFixed(2))
                newpos.tpvalue = parseFloat((Math.abs(newpos.oprice - newpos.otp) * pipvalue * 1000).toFixed(2))
                newpos.filldate = value.time
                newpos.finalmoney = 0
                setpositionslist([...positionslist, newpos])
                removeorderbyid(id)
            }
        });
        positionslist.forEach((position, id) => {
            let pipvalue = 0.0001/position.oprice*position.ovolume*100000
            if (position.otype === "buy" && sendable) {
                let distance = parseFloat(((value.close - position.oprice) * pipvalue * 1000).toFixed(2))
                position.finalmoney = Math.round(distance, 2)
                setupnl(position.finalmoney)
            }
            if (position.otype === "sell" && sendable) {
                let distance = parseFloat(((position.oprice - value.close) * pipvalue * 1000).toFixed(2))
                position.finalmoney = Math.round(distance, 2)
                setupnl(position.finalmoney)
            }
            //let pipvalue = 0.0001/value.close*newpos.ovolume*100000
            //let distance = parseFloat((Math.abs(newpos.oprice - newpos.otp) * pipvalue * 1000).toFixed(2))

            if (position.osl >= value.low && position.osl <= value.high && sendable) {
                setsendable(false)
                let forhistory = positionslist[id]
                //forhistory.finalmoney = forhistory.slvalue
                forhistory.closedate = value.time
                //setupnl(upnl - forhistory.finalmoney)
                updatehistory(forhistory)
                removepositionbyid(id)
                sethistorylist([...historylist, forhistory])
                //calcaccbalance()
            }
            if (position.otp >= value.low && position.otp <= value.high && sendable) {
                setsendable(false)
                let forhistory = positionslist[id]
                //forhistory.finalmoney = forhistory.tpvalue
                forhistory.closedate = value.time
                //setupnl(upnl - forhistory.finalmoney)
                updatehistory(forhistory)
                removepositionbyid(id)
                sethistorylist([...historylist, forhistory])
                //calcaccbalance()
            }
        });
        setCurrentPrice(value);
        //setCurrentDate(value.time);
    };

    // this order fills only for buy or somehintg like  that and has no fill date
    const handleordersubmit = (event) => {
        event.preventDefault();
        if (oentry >= currentPrice?.low && oentry <= currentPrice?.high) {
            var newpos = {
                otype: oside,
                oprice: oentry,
                ovolume: ovolume,
                osl: osl,
                otp: otp,
                finalmoney: 0,
            }
            let pipvalue = 0.0001/currentPrice.close*newpos.ovolume*100000
            newpos.slvalue = parseFloat(-1 * (Math.abs(newpos.oprice - newpos.osl) * pipvalue * 1000).toFixed(2))
            newpos.tpvalue = parseFloat((Math.abs(newpos.oprice - newpos.otp) * pipvalue * 1000).toFixed(2))
            //newpos.filldate = gettimestamp(currentDate)
            setpositionslist([...positionslist, newpos])
            setoFormData({
                otype: "buy",
                oprice: '',
                ovolume: '',
                osl: '',
                otp: '',
            })
        } else {
            setorderlist([...orderlist, {
                otype: oside,
                oprice: oentry,
                ovolume: ovolume,
                osl: osl,
                otp: otp,
            }])
        }
        setorderbox(false)
        /*
        if (oFormData.oprice && oFormData.ovolume) {
            console.log(orderlist)
            //tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype).setPrice(oFormData.oprice).setQuantity(oFormData.ovolume).setText("order one")
            //tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype === "buy" ? "sell" : "buy").setPrice(oFormData.otp).setQuantity(oFormData.ovolume).setText("Take Profit")
            //tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype === "buy" ? "sell" : "buy").setPrice(oFormData.osl).setQuantity(oFormData.ovolume).setText("Stop Loss")
            
            //not used
            if (oFormData.oprice >= currentPrice.low && oFormData.oprice <= currentPrice.high) {
                var newpos = oFormData
                let pipvalue = 0.0001/currentPrice.close*newpos.ovolume*100000
                newpos.slvalue = parseFloat(-1 * (Math.abs(newpos.oprice - newpos.osl) * pipvalue * 1000).toFixed(2))
                newpos.tpvalue = parseFloat((Math.abs(newpos.oprice - newpos.otp) * pipvalue * 1000).toFixed(2))
                //newpos.filldate = gettimestamp(currentDate)
                setpositionslist([...positionslist, newpos])
                setoFormData({
                    otype: "buy",
                    oprice: '',
                    ovolume: '',
                    osl: '',
                    otp: '',
                })
                console.log(newpos)
            }
            else {
                tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype).setPrice(oFormData.oprice).setQuantity(oFormData.ovolume).setText("order one")
                tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype === "buy" ? "sell" : "buy").setPrice(oFormData.otp).setQuantity(oFormData.ovolume).setText("Take Profit")
                tvWidget.activeChart().createOrderLine().setDirection(oFormData.otype === "buy" ? "sell" : "buy").setPrice(oFormData.osl).setQuantity(oFormData.ovolume).setText("Stop Loss")
                setorderlist([...orderlist, oFormData])
            }
            
        }*/
    };
    const handlepositionchange = (event) => {
        event.preventDefault();
        if (positionslist[positioneditdata].osl !== Number(event.target[0].value)) {

            const updatedData = positionslist.map((item, index) => {
              if (index === positioneditdata) {
                return { ...item, osl: Number(event.target[0].value) };
              }
              return item;
            });
            setpositionslist(updatedData);
        }
        if (positionslist[positioneditdata].otp !== Number(event.target[1].value)) {
            const updatedData = positionslist.map((item, index) => {
              if (index === positioneditdata) {
                return { ...item, otp: Number(event.target[1].value) };
              }
              return item;
            });
            setpositionslist(updatedData);
        }
        if (event.target[2].value !== "0") {
            let ptosave
            const updatedData = positionslist.map((item, index) => {
              if (index === positioneditdata) {

                ptosave = { ...item }
                ptosave.finalmoney = Math.round(item.finalmoney * (Number(event.target[2].value) / 100), 2)
                ptosave.ovolume = Math.round(item.ovolume * (Number(event.target[2].value) / 100), 2)

                if (Math.round(item.finalmoney - item.finalmoney * (Number(event.target[2].value) / 100), 2) === 0) {
                    return null;
                }

                return {
                    ...item,
                    finalmoney: Math.round(item.finalmoney - item.finalmoney * (Number(event.target[2].value) / 100), 2),
                    ovolume: Math.round(item.ovolume - item.ovolume * (Number(event.target[2].value) / 100), 2)
                };
              }
              return item;
            });
            event.target[2].value = "0"
            console.log(updatedData.filter(item => item !== null))
            setpositionslist(updatedData.filter(item => item !== null));
            updatehistory(ptosave)
            sethistorylist([...historylist, ptosave])
            //updatehistory(updatedData[positioneditdata])
            //sethistorylist([...historylist, updatedData[positioneditdata]])
        }
    }
    const handleoformdata = (event) => {
        
        if (event.target.name === "oprice") {
            setoentry(event.target.value)
        }
        if (event.target.name === "ovolume") {
            setovolume(event.target.value)
        }
        if (event.target.name === "osl") {
            let topvalue = (accbalance * (event.target.value / 100))
            let newvolume = topvalue / ((100000*0.00001) * Math.abs(oentry - osl) * 10000)
            setovolume(newvolume.toFixed(2))

            setosl(event.target.value)
        }
        if (event.target.name === "otp") {
            setotp(event.target.value)
        }
        if (event.target.name === "orisk") {
            //let pipvalue = 0.0001/oentry*Math.round(accbalance * (event.target.value / 100), 2)*100
            //let pipv = topvalue/Math.abs(oentry - osl) * 100000
            //console.log(Math.abs(oentry - osl) * 10000)
            //console.log(topvalue / ((100000*0.0001) * Math.abs(oentry - osl) * 100000))
            //setovolume(Math.round(accbalance * (event.target.value / 100), 2))
            //setovolume((accbalance * (event.target.value / 100)) / (pipv * Math.abs(oentry - osl) * 10000))
            let topvalue = (accbalance * (event.target.value / 100))
            let newvolume = topvalue / ((100000*0.00001) * Math.abs(oentry - osl) * 10000)
            setovolume(newvolume.toFixed(2))
        }
        setoFormData({
            ...oFormData,
            [event.target.name]: event.target.value
        });
    };
    const handlepositionedit = (event) => {
        if (event.target.name === "osl") {
            setosl(event.target.value)
        }
        if (event.target.name === "otp") {
            setotp(event.target.value)
        }
        /*
        if (event.target.name === "osl") {

            const updatedData = positionslist.map((item, index) => {
              if (index === positioneditdata) {
                return { ...item, osl: Number(event.target.value) };
              }
              return item;
            });
            
            setpositionslist(updatedData);
        }
        if (event.target.name === "otp") {

            const updatedData = positionslist.map((item, index) => {
              if (index === positioneditdata) {
                return { ...item, otp: Number(event.target.value) };
              }
              return item;
            });
            
            setpositionslist(updatedData);
        }*/
    };
    const handlemousedown = (e) => {
        /*
        if (!e.target.value) {e.target.value = parseFloat(currentPrice.close)}
        setoFormData({
            ...oFormData,
            [e.target.name]: parseFloat(currentPrice.close)
        });
        */
    };
    const removeorderbyid = (indexToRemove) => {
        setorderlist(currentItems => 
          currentItems.filter((_, index) => index !== indexToRemove)
        );
    };
    const removepositionbyid = (indexToRemove) => {
        setpositionslist(currentItems => 
          currentItems.filter((_, index) => index !== indexToRemove)
        );
        setTimeout(() => {
            //sendable.current = true
        }, 2000);
    };
    const togglepositioneditbox = (pindex) => {
        //console.log(positioneditdata)
        //setpositioneditdata(positionslist[pindex])
        if (positioneditdata >= 0) {setpositioneditdata(-1)} else {setpositioneditdata(pindex)}
    };

    const toggleorderbox = () => {
        if (orderbox) {setorderbox(false)} else {setorderbox(true)}

        //var odata = []
        //var postype = []
        tvWidget.activeChart().getAllShapes().forEach(s => {
          if (s.name === "long_position") {
            setoside("buy")
            setoentry(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._entryPriceAxisView._axisRendererData.text))
            setosl(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._stopPriceAxisView._axisRendererData.text))
            setotp(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._profitPriceAxisView._axisRendererData.text))
          }
          if (s.name === "short_position") {
            setoside("sell")
            setoentry(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._entryPriceAxisView._axisRendererData.text))
            setosl(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._stopPriceAxisView._axisRendererData.text))
            setotp(parseFloat(tvWidget.activeChart().getShapeById(s.id)._source._profitPriceAxisView._axisRendererData.text))
          }
        });
    
    }


    const mousedown = (e) => {
        e.target.style.width = "200px"
        e.target.style.height = "200px"
        e.target.style.transform = "translate(-50%, -50%)"
        isclicked.current = true
        cords.current.startx = e.clientX
        cords.current.starty = e.clientY
    }
    const mouseup = (e) => {
        e.target.style.width = "40px"
        e.target.style.height = "40px"
        e.target.style.transform = "translate(0, -50%)"
        isclicked.current = false
        cords.current.lasty = floatcontrolsref.current.offsetTop
        cords.current.lastx = floatcontrolsref.current.offsetLeft
    }
    const mousemove = (e) => {
        if (!isclicked.current) return;

        const nextx = e.clientX - cords.current.startx + cords.current.lastx
        const nexty = e.clientY - cords.current.starty + cords.current.lasty

        floatcontrolsref.current.style.top = String(nexty)+"px"
        floatcontrolsref.current.style.left = String(nextx)+"px"
    }

    return (
        <div className='session-screen'>
            {
                orderbox ? 
                <div className="order-box-cont-bg" onClick={() => {setorderbox(false)}}>
                    <div className="order-box-cont" ref={floatcontrolsref} onClick={(e) => {e.stopPropagation()}}>
                        <div className="order-box-header">
                            <div className="order-box-drag" onMouseDown={(e) => mousedown(e)} onMouseUp={(e) => mouseup(e)} onMouseMove={(e) => mousemove(e)} onMouseLeave={mouseup}></div>
                            <p className="float-text">&#9776;</p>
                            <p className='order-box-close' onClick={() => {setorderbox(false)}}>&#10006;</p>
                        </div>
                        <form id='orderform' onSubmit={handleordersubmit} className="order-form">
                            <label htmlFor="otype">Order type</label>
                                <select value={oside} required className='order-input' name='otype' onChange={handleoformdata}>
                                    <option value="buy">Buy</option>
                                    <option value="sell">Sell</option>
                                    <option value="buylimit">Buy Limit</option>
                                    <option value="selllimit">Sell Limit</option>
                                    <option value="buystop">Buy Stop</option>
                                    <option value="sellstop">Sell Stop</option>
                                </select>
                            <label htmlFor="oprice">Entry Price</label>
                            <input required name='oprice' type="number" value={oentry} step={0.00001} className="order-input" onMouseDown={handlemousedown} onChange={handleoformdata} />
                            <label htmlFor="ovolume">Risk</label>
                            <input required name='orisk' type="number" step={0.1} className="order-input" onChange={handleoformdata} />
                            <label htmlFor="ovolume">Lots</label>
                            <input required name='ovolume' type="number" value={ovolume} step={0.01} className="order-input" onChange={handleoformdata} />
                            <label htmlFor="osl">Stop Loss</label>
                            <input required name='osl' type="number" value={osl} step={0.00001} className="order-input" onMouseDown={handlemousedown} onChange={handleoformdata} />
                            <label htmlFor="otp">Take Profit</label>
                            <input required name='otp' type="number" value={otp} step={0.00001} className="order-input" onMouseDown={handlemousedown} onChange={handleoformdata} />
                        </form>
                        <button type='submit' form='orderform' className="order-form-btn">Submit</button>
                    </div>
                </div>
                : ""
            }
            {
                positioneditdata >= 0 ? 
                <div className="pos-box-cont-bg">
                    <div className="order-box-cont">
                        <div className="order-box-header"><p className="float-text">&#9776;</p>
                            <p className='order-box-close' onClick={() => {setpositioneditdata(-1)}}>&#10006;</p>
                        </div>
                        <form id='orderform' className="order-form" onSubmit={handlepositionchange}>
                            <label htmlFor="osl">Change Stop Loss</label>
                            <input required name='osl' type="number" value={osl} step={0.00001} className="order-input" onMouseDown={handlemousedown} onChange={handlepositionedit} />
                            <label htmlFor="otp">Change Take Profit</label>
                            <input required name='otp' type="number" value={otp} step={0.00001} className="order-input" onMouseDown={handlemousedown} onChange={handlepositionedit} />
                            <label htmlFor="closepartial">Close % of entry volume</label>
                            <select required className='pos-close-input' name='otype'>
                                <option value="0">-</option>
                                <option value="25">25%</option>
                                <option value="50">50%</option>
                                <option value="75">75%</option>
                                <option value="100">100%</option>
                            </select>
                        </form>
                        <button type='submit' form='orderform' className="order-form-btn">Close Selected</button>
                    </div>
                </div>
                : ""
            }
            <div className="chart-cont" id='chart-cont' ref={ref} style={{height:"calc(100vh - 60px)", width: "100%"}}>
                {/* <Schart/> */}
                <Game handlenewprice={handlenewprice} currentprogres={sessiondata.lastcandle} sendable={sendable} setsendable={setsendable} sid={sid} setoside={setoside} setoentry={setoentry} setosl={setosl} setotp={setotp} />
                {/* <h1 style={{margin: '0'}}>TV chart would be displayed here.</h1> */}
                {/* <SessionChart/> */}
                {/* <Barchart updateprice={handlenewprice} lastcandle={sessiondata.lastcandle} updatedate={updatedate}/> */}
                {/* <DukascopyChart/> */}
            </div>
            <div className="session-data-cont">
                <div className="chartbar-cont">
                    <div className="bar-stats-cont">
                        <img src={logo} alt="" className="bar-logo" onClick={() => {window.location = "/dashboard"}}/>
                        <div className="acc-info-cont">
                            <p className="acc-info-title">Account Balance:</p>
                            <p className="acc-info-value">$ {accbalance}</p>
                        </div>
                        <div className="acc-info-cont">
                            <p className="acc-info-title">PnL:</p>
                            <p className="acc-info-value">$ { pnl }</p>
                        </div>
                        <div className="acc-info-cont">
                            <p className="acc-info-title">Unrealized PnL:</p>
                            <p className="acc-info-value">$ { upnl }</p>
                        </div>
                        {/* <div className="acc-info-cont">
                            <p className="acc-info-title">Unrealized:</p>
                            <p className="acc-info-value">$ 1 000.00</p>
                        </div> */}
                    </div>
                    <div className="bar-operations-cont" style={{justifyContent: 'end'}}>
                        <a href='#ops' className="positions-btn">Positions</a>
                        <button className="neworder-btn" onClick={toggleorderbox}>Order</button>
                    </div>
                </div>
                {/* <div className="chartbar-cont">
                    <div className="session-data-controls-left">
                        <p className="data-control-option">Open positions</p>
                        <p className="data-control-option">Open positions</p>
                        <p className="data-control-option">Open positions</p>
                    </div>
                    <div className="session-data-controls-right">
                        <button className="session-go-stats">Stats</button>
                    </div>
                </div> */}
                <div className="operations-cont" id='ops'>
                    <div className="list-operations">
                        <h1 className='session-table-title' >Orders & Positions</h1>
                        <table className='session-operations-table'>
                            <thead>
                                <tr>
                                    <th>Side</th>
                                    <th>Volume</th>
                                    <th>Entry</th>
                                    <th>SL</th>
                                    <th>TP</th>
                                    <th>PnL</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    positionslist.map((it, id) => {
                                        return (
                                            <tr key={id} style={{backgroundColor:'#17171780'}}>
                                                <td>{it.otype}</td>
                                                <td>{it.ovolume}</td>
                                                <td>{it.oprice}</td>
                                                <td>{it.osl}</td>
                                                <td>{it.otp}</td>
                                                <td>{it?.finalmoney}</td>
                                                <td onClick={() => togglepositioneditbox(id)}>&#9998;</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    orderlist.map((it, id) => {
                                        return (
                                            <tr key={id}>
                                                <td>{it.otype}</td>
                                                <td>{it.ovolume}</td>
                                                <td>{it.oprice}</td>
                                                <td>{it.osl}</td>
                                                <td>{it.otp}</td>
                                                <td onClick={() => removeorderbyid(id)}>X</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="list-operations">
                        <h1 className='session-table-title' >Trade History</h1>
                        <table className='session-operations-table'>
                            <thead>
                                <tr>
                                    <th>Side</th>
                                    <th>Volume</th>
                                    <th>Entry</th>
                                    <th>SL</th>
                                    <th>TP</th>
                                    <th>Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sessiondata.operations.map((it, id) => {
                                        return (
                                            <tr key={id}>
                                                <td>{it.otype}</td>
                                                <td>{it.ovolume}</td>
                                                <td>{it.oprice}</td>
                                                <td>{it.osl}</td>
                                                <td>{it.otp}</td>
                                                <td>$ {it.finalmoney}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    historylist.map((it, id) => {
                                        return (
                                            <tr key={id}>
                                                <td>{it.otype}</td>
                                                <td>{it.ovolume}</td>
                                                <td>{it.oprice}</td>
                                                <td>{it.osl}</td>
                                                <td>{it.otp}</td>
                                                <td>$ {it.finalmoney}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}